import React, { useEffect } from "react"
import image from '../images/sessions/SpiritualHomes_Landing_LITORAL.jpg'

export default function CoronaSessionLitoral({}) {
    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Corona Session Litoral",
            pageType: "Content page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/coronasessionLitoral",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "" ,
        })
    }, [])

  return (
    <div style={{height: 'auto', width:'100vw', marginBottom: 0}}>
      <img src={image} alt={'menu6'} style={{height: 'auto', width: '100vw', objectFit: 'contain', marginBottom: 0}}/>
    </div>
  )
}